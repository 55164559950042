import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Container, media } from '../ui';
import { Link, graphql } from 'gatsby';
import { get } from 'lodash';
import Dotdotdot from 'react-dotdotdot';
import Hero from '../components/Hero';
import Seo from '../components/Seo';

const SkillGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${({ theme }) => theme.spacing(2)};
  grid-row-gap: ${({ theme }) => theme.spacing(3)};
  padding-right: 0;
  padding-left: 0;

  ${media.tablet`  grid-template-columns: repeat(1, 1fr);padding-right: ${({
    theme,
  }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  `}
  ${media.desktop`  grid-template-columns: repeat(1, 1fr); padding: 0;
  `}
`;

const SkillCardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const SkillCardContent = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(2.5)};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.text};
  letter-spacing: 0.0375em;
  line-height: 1.5;

  h3 {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.text};
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const TextLink = styled.p`
  color: ${({ theme }) => theme.secondary} !important;
  text-decoration: underline;
  line-height: 1.5;
  font-weight: bold;
`;

const Skills = ({ data }) => {
  const skills = get(data, 'allDatoCmsSkill.edges');
  return (
    <Layout variant="withAvocadoPattern">
      <Seo title="Mes compétences"></Seo>
      <Hero type="skills" />
      <Container variant="main" paddingSize="none" size="tight">
        <SkillGrid>
          {skills.map(({ node }) => (
            <SkillCardLink to={`/competences/${node.slug}`} key={node.slug}>
              <SkillCardContent>
                <h3>{node.title}</h3>
                <Dotdotdot clamp={3}>{node.kicker}</Dotdotdot>
                <TextLink>Lire plus</TextLink>
              </SkillCardContent>
            </SkillCardLink>
          ))}
        </SkillGrid>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allDatoCmsSkill(sort: { fields: meta___updatedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          kicker
          content
        }
      }
    }
  }
`;

export default Skills;
